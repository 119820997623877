<template>
  <div>
    <v-card flat class="px-5 mx-sm-5 mt-10">
      <v-row>
        <v-col class="col-12 col-md-6 pt-0">
          <v-card-title> {{ $t('PartQuota') }} </v-card-title>
          <v-text-field
            outlined
            type="number"
            label="Quota"
            class="ml-5 mt-2"
            @input="featuresModified = true"
            v-model="supplierPartQuota"
            append-icon="fas fa-database"
          >
          </v-text-field>
          <v-card-title> {{ $t('privateWarehousePriceFactor') }} </v-card-title>
          <v-text-field
            outlined
            type="number"
            min="0.1"
            label="Factor"
            class="ml-5 mt-2"
            @input="featuresModified = true"
            v-model="privateWarehousePriceFactor"
            prepend-icon="fas fa-times"
          >
          </v-text-field>
        </v-col>
        <v-col class="col-12 col-md-6 pt-0">
          <v-card-title>
            {{ $t('deliveryPricing') }}     
          </v-card-title>
          <v-card-text>
            <v-switch
              class="ml-1"
              inset
              color="success"
              :input-value="manageDelivery === 'SUPPLIER'"
              @change="
                manageDelivery =
                  manageDelivery === 'SUPPLIER' ? 'BCM' : 'SUPPLIER'
                featuresModified = true
              "
              :label="
                manageDelivery === 'SUPPLIER'
                  ? $t('deliverySupplier')
                  : $t('deliveryBCM')
              "
            ></v-switch>
            <v-text-field
              class="mr-5 mt-2"
              type="number"
              :label="$t('PricePerBox')"
              outlined
              append-icon="fas fa-euro-sign"
              :rules="supplierDeliveryPricesRules"
              v-model="supplierDeliveryDefaultPrice"
              @input="featuresModified = true"
              :disabled="manageDelivery === 'BCM'"
            ></v-text-field>
            <v-text-field
              class="mr-5 mt-2"
              type="number"
              :label="$t('maxBoxes')"
              :hint="$t('hintMaxBoxes')"
              outlined
              append-icon="fas fa-box-open"
              v-model="supplierDeliveryMaxBoxes"
              @input="featuresModified = true"
              :disabled="manageDelivery === 'BCM'"
            ></v-text-field>
          </v-card-text>
        </v-col>
      </v-row>
      <div class="pb-4 pl-3">
        <v-btn
          color="error"
          class="text-none mr-2"
          :title="$t('Cancel')"
          @click="getSupplierFeatures"
        >
          <v-icon small class="mr-1">
            fas fa-ban
          </v-icon>
          {{ $t('Cancel') }}
        </v-btn>
        <v-btn
          :disabled="!featuresModified"
          color="success"
          class="text-none"
          @click="modifySupplierFeatures"
          :title="$t('valid')"
        >
          <v-icon small class="mr-1">
            fas fa-check
          </v-icon>
          {{ $t('valid') }}
        </v-btn>
      </div>
    </v-card>
    <v-divider class="mx-12"></v-divider>
    <v-card flat class="px-5 ml-5 mt-10">
      <v-card-title>{{ $t('customLocation') }}</v-card-title>
      <div class="text-right"></div>
      <v-card-title>
        <v-btn
          color="primary"
          :disabled="manageDelivery === 'BCM'"
          class="text-none"
          @click="dialogAddCustomLocationPrice = true"
        >
          <v-icon small class="mr-2">
            fas fa-plus-circle
          </v-icon>{{ $t('addCustomLocationPrice') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchCustomLocation"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <div class="text-right"></div>
      <v-card-text>
        <v-simple-table :search="searchCustomLocation">
          <template v-slot:default>
            <thead>
              <tr>
                <th scope="col" class="text-left">
                  {{ $t('Location') }}
                </th>
                <th scope="col" class="text-left">
                  {{ $t('Price') }}
                </th>
                <th scope="col" class="text-left">
                  {{ $t('Action') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value, item) in supplierDeliveryPricesCustomLocation"
                :key="item"
              >
                <td>{{ $t(`country.${item}`) }}</td>
                <td>
                  {{ value }} € pour {{ supplierDeliveryMaxBoxes }}
                  carton(s)
                </td>
                <td>
                  <v-btn
                    small
                    color="error"
                    @click="passDataDeleteCustomPrice(item)"
                    :disabled="manageDelivery === 'BCM'"
                  >
                    <v-icon small>
                      fas fa-trash
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <!--DIALOG FOR ADD CUSTOM LOCATION PRICE-->
    <!--DIALOG FOR ADD CUSTOM LOCATION PRICE-->
    <!--DIALOG FOR ADD CUSTOM LOCATION PRICE-->
    <!--DIALOG FOR ADD CUSTOM LOCATION PRICE-->
    <v-dialog
      v-model="dialogAddCustomLocationPrice"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="title">{{ $t('addCustomLocationPrice') }}</span>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text class="pb-0">
          <v-container>
            <v-card flat class="px-5 mt-10">
              <v-form ref="customLocationForm">
                <v-autocomplete
                  outlined
                  v-model="customLocation"
                  :rules="supplierDeliveryLocationRules"
                  :items="allCountries"
                  item-text="name"
                  item-value="code"
                  append-icon="fas fa-search-location"
                  :label="$t('Location')"
                >
                </v-autocomplete>
              </v-form>
              <v-form ref="customPriceForm">
                <v-text-field
                  type="number"
                  :rules="supplierDeliveryPricesRules"
                  outlined
                  v-model="customPrice"
                  append-icon="fas fa-euro-sign"
                  :label="$t('Price')"
                >
                </v-text-field>
              </v-form>
            </v-card>
          </v-container>
        </v-card-text>
        <v-divider class="mb-5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="text-none"
            @click="
              clearFormAddCustomLocationPrice()
              dialogAddCustomLocationPrice = false
            "
          >
            <v-icon small class="mr-1">
              fas fa-ban
            </v-icon>{{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="success"
            :loading="loadingAddCustomLocationPrice"
            :disabled="loadingAddCustomLocationPrice"
            class="text-none"
            @click="addSupplierDeliveryPrices"
          >
            <v-icon small class="mr-1">
              fas fa-check
            </v-icon>{{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG FOR CONFIRMATION OF DELETE CUSTOM LOCATION PRICE-->
    <!-- DIALOG FOR CONFIRMATION OF DELETE CUSTOM LOCATION PRICE-->
    <!-- DIALOG FOR CONFIRMATION OF DELETE CUSTOM LOCATION PRICE-->
    <!-- DIALOG FOR CONFIRMATION OF DELETE CUSTOM LOCATION PRICE-->
    <v-dialog
      v-model="dialogDeleteCustomLocationPrice"
      max-width="500px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="title">{{ $t('deleteCustomLocationPrice') }}</span>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <div>{{ $t('confirmDeleteCustomPrice') }}</div>
        </v-card-text>
        <v-divider class="mb-5"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="text-none"
            @click="dialogDeleteCustomLocationPrice = false"
          >
            <v-icon small class="mr-1">
              fas fa-ban
            </v-icon>{{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="text-none"
            @click="deleteSupplierDeliveryPrices(deleteCustomPrice)"
          >
            <v-icon small class="mr-1">
              fas fa-check
            </v-icon>{{ $t('valid') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { EventBus, ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';
import countryList from 'country-list';

const i18nData = require('./pageSupplierFeaturesAndDelivery.i18n.json');

export default {
  name: 'PageSupplierFeaturesAndDelivery',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      featuresModified: false,
      searchCustomLocation: '',
      customLocation: null,
      customPrice: null,
      deleteCustomPrice: null,
      dialogAddCustomLocationPrice: false,
      dialogDeleteCustomLocationPrice: false,
      loadingAddCustomLocationPrice: false,
      allCountries: [],
      manageDelivery: null,
      supplierDeliveryPricesCustomLocation: null,
      supplierDeliveryDefaultPrice: null,
      supplierDeliveryMaxBoxes: null,
      supplierPartQuota: null,
      privateWarehousePriceFactor: null,
      supplierDeliveryPricesRules: [
        (value) => !!value || value === '0' || value === 0 || this.$t('priceRequired'),
        (value) => Number(value) >= 0 || this.$t('pricePositive'),
      ],
      supplierDeliveryLocationRules: [
        (value) => !!value || this.$t('locationRequired'),
      ],
    };
  },
  created() {
    this.allCountries = countryList.getData();
    this.allCountries.forEach((country) => {
      country.name = this.$t(`country.${country.code}`);
    });
    /**
     * GET SUPPLIER
     */
    this.getSupplierFeatures();
  },
  methods: {
    /**
     * GET SUPPLIER FEATURES
     */
    getSupplierFeatures() {
      this.$apiInstance
        .getSupplierFeatures(this.$route.params.supplierUUID)
        .then((supplierFeaturesData) => {
          this.supplierPartQuota = supplierFeaturesData.internalPartQuota;
          this.privateWarehousePriceFactor = supplierFeaturesData.privateWarehousePriceFactor;
          this.manageDelivery = supplierFeaturesData.manageDelivery;
          this.supplierDeliveryPricesCustomLocation =
            supplierFeaturesData.supplierDeliveryPrices.customLocation;
          this.supplierDeliveryDefaultPrice =
            supplierFeaturesData.supplierDeliveryPrices.default;
          this.supplierDeliveryMaxBoxes =
            supplierFeaturesData.supplierDeliveryPrices.maxBoxes;
        }).finally(() => {
          this.featuresModified = false;
        });
    },
    /**
     * ADD SUPPLIER DELIVERY PRICES
     */
    addSupplierDeliveryPrices() {
      if (
        this.$refs.customLocationForm.validate() &&
        this.$refs.customPriceForm.validate()
      ) {
        this.loadingAddCustomLocationPrice = true;
        const addSupplierDeliveryPricesBody = new this.$BcmModel.AddSupplierDeliveryPricesBody(
          this.customLocation,
          Number(this.customPrice)
        );
        this.$apiInstance
          .addSupplierDeliveryPrices(
            this.$route.params.supplierUUID,
            addSupplierDeliveryPricesBody
          )
          .then(
            () => {
              this.getSupplierFeatures();
              this.$notification.notify('SUCCESS',this.$t('locationPriceSuccessfullyAdded'));
            },
            (error) => {
              /**
               * ERROR ADD PRINTER
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loadingAddCustomLocationPrice = false;
            this.dialogAddCustomLocationPrice = false;
            this.clearFormAddCustomLocationPrice();
            this.getSupplierFeatures();
          });
      }
    },
    /**
     * CLEAR DATA WHEN CANCEL DIALOG
     */
    clearFormAddCustomLocationPrice() {
      this.$refs.customLocationForm.reset();
      this.$refs.customPriceForm.reset();
    },
    /**
     * DELETE SUPPLIER DELIVERYPRICES
     */
    deleteSupplierDeliveryPrices(location) {
      this.$apiInstance
        .deleteSupplierDeliveryPrices(this.$route.params.supplierUUID, location)
        .then(() => {
          this.getSupplierFeatures();
          this.dialogDeleteCustomLocationPrice = false;
          this.$notification.notify('SUCCESS',this.$t('customPriceSuccessfullyDeleted'));
        });
    },
    /**
     * FUNCTION TO PASS DATA TO DELETE SUPPLIER DELEVERY PRICES
     */
    passDataDeleteCustomPrice(location) {
      this.deleteCustomPrice = location;
      this.dialogDeleteCustomLocationPrice = true;
    },
    /**
     * MODIFY SUPPLIER FEATURES
     */
    modifySupplierFeatures() {
      const modifySupplierFeaturesBody = new this.$BcmModel.ModifySupplierFeaturesBody(
        parseInt(this.supplierPartQuota),
        this.manageDelivery,
        Number(this.supplierDeliveryMaxBoxes),
        Number(this.supplierDeliveryDefaultPrice),
        parseFloat(this.privateWarehousePriceFactor),
      );
      this.$apiInstance
        .modifySupplierFeatures(
          this.$route.params.supplierUUID,
          modifySupplierFeaturesBody
        )
        .then(
          () => {
            this.$notification.notify('SUCCESS',this.$t('SupplierFeaturesSuccessfullyModified'));
            EventBus.$emit('reloadOrganizationSelector');
          },
          (error) => {
            /**
             * ERROR MODIFY FEATURES
             */
            this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.getSupplierFeatures();
        });
    },
  },
};
</script>
